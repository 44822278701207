<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Kipukkasztandó buborékok és lángháborúk 
          </h2>
          <p class="hashText">– A csoportosan elkövetett bullyingról</p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="10" class="side-gap offset-lg-1">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                Az interneten létező csoportok és közösségek - legyen szó egy közösségi médián kialakult csoportról vagy magáról az egész platform felhasználói köréről - sokszor eltérhetnek eredeti céljuktól, mely az együttműködés, a tapasztalat- és élménymegosztás és az információcsere. Ilyenkor sajnos gyakran válhatnak áldozattá és elkövetővé is az online bántalmazás terén. </p>

                <p class="text">
                A következő cikkben a csoportok, közösség által végzett cyberbullyingról lesz szó, mely ugyan alapjaiban megegyezik az egyénileg elkövetett bántalmazással, azonban lefolyását, módjait és pszichológiai lenyomatát tekintve nagyban eltérő is lehet.</p>

                <p class="text">
                Az online bántalmazás különböző formái közé tartozik például a személyes adatok vagy fotók jogtalan közzététele, az álhírek terjesztése, a sértő megjegyzések, a trágár beszéd, a fenyegető üzenetek vagy a közösségi oldalakon történő kiközösítés. A felsoroltak közül nyilvánvaló, hogy a kiközösítés kifejezetten csoportos bántalmazási forma, azonban az említett bántalmazási formák mindegyike megjelenhet mind egyszemélyes, mind csoportos cyberbullying során is.</p>
                <p class="text">
                A csoportok és közösségek által végzett online bántalmazásnak számos oka lehet. A leggyakoribb okok közé tartozik a társadalmi kirekesztés és az előítélet, melyeket az interneten könnyen lehet megosztani és erősíteni, főként a csoportok vélt vagy valós névtelensége okán. A közösség tagjai gyakran hasonló érdeklődési körrel rendelkeznek, és ez lehetőséget ad arra, hogy az online bántalmazás áldozatait könnyen kiközösítsék, illetve, hogy az elkövetők egyfajta társadalmi elfogadottságot kapjanak. Ezt a jelenséget, mely az offline világban is jelen van, véleménybuboréknak vagy információ szűrőbuboréknak hívjuk. Röviden azt jelenti, hogy szeretjük körülvenni magunkat olyan emberekkel, akiknek érdeklődési körét, általános elveit, de legfőképpen véleményét hasonlónak gondoljuk magunkéhoz, így csökkentve a vitába bocsátkozás lehetőségét. Ez azonban azt is jelenti, hogy véleményünk nem kerül ütköztetésre, nem kérdőjeleződik meg, így a nyitottság helyett beleragadunk egyfajta szemléletbe.</p>
                <p class="text">
                Az online bántalmazás megelőzése érdekében fontos az interneten folytatott kommunikációra vonatkozó szabályok és etikai normák tiszteletben tartása. Ha mások véleményével nem is értünk egyet, magát az embert tisztelettel kell kezelnünk. </p>
                <p class="text">
                A csoportoknak és közösségeknek felelősséget kell vállalniuk azért, hogy az interneten tiszteletteljesen kommunikáljanak, és ne szolgáljanak az online bántalmazás és társadalmi kirekesztés eszközeként.</p>
            </div>
            </b-col>
            <b-col cols="12" class="order-4 order-lg-1">
              <img src="images/harmas.jpg" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Kipukkasztandó buborékok és lángháborúk ", 
        desc: "Az interneten létező csoportok és közösségek - legyen szó egy közösségi médián kialakult csoportról vagy magáról az egész platform felhasználói köréről - sokszor eltérhetnek eredeti céljuktól, mely az együttműködés, a tapasztalat- és élménymegosztás és az információcsere.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Kipukkasztandó buborékok és lángháborúk ",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Az interneten létező csoportok és közösségek - legyen szó egy közösségi médián kialakult csoportról vagy magáról az egész platform felhasználói köréről - sokszor eltérhetnek eredeti céljuktól, mely az együttműködés, a tapasztalat- és élménymegosztás és az információcsere."        
    })
  }
}
</script>